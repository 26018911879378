import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ebooks',
  templateUrl: './ebooks.component.html',
  styleUrls: ['./ebooks.component.scss']
})
export class EbooksComponent implements OnInit {

  buscaTotal = -1;
  ebooks = [];

  public newTotal = 27;
  tamanho: number;

  constructor() { }

  public ebooksList: any [] = [
    {
      img: './assets/images/conteudo/img-o-roi-da-ia-generativa.png',
      alt: 'o-roi-da-ia-generativa',
      descricao: 'Manual de Prompts do Gemini para Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/o-roi-da-ia-generativa'
    },
    {
      img: './assets/images/conteudo/img-como-o-google-workspace-ajuda-a-proteger-sua-empresa.png',
      alt: 'como-o-google-workspace-ajuda-a-proteger-sua-empresa',
      descricao: 'Como o Google Workspace ajuda a proteger sua empresa',
      link: 'https://sucesso.qinetwork.com.br/como-o-google-workspace-ajuda-a-proteger-sua-empresa'
    },
    {
      img: './assets/images/conteudo/img-o-futuro-dos-dados.png',
      alt: 'o-futuro-dos-dados',
      descricao: 'O futuro dos dados será unificado, maleável e acessível.',
      link: 'https://sucesso.qinetwork.com.br/o-futuro-dos-dados'
    },
    {
      img: './assets/images/conteudo/img-manual-de-prompts-do-gemini-para-google-workspace.png',
      alt: 'manual-de-prompts-do-gemini-para-google-workspace',
      descricao: 'Manual de Prompts do Gemini para Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/manual-de-prompts-do-gemini-para-google-workspace'
    },
    {
      img: './assets/images/conteudo/img-impacto-economico-total-do-google-workspace.png',
      alt: 'impacto-economico-total-do-google-workspace',
      descricao: 'Impacto Econômico Total do Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/impacto-economico-total-do-google-workspace'
    },
    {
      img: './assets/images/conteudo/img-guia-executivo-ia-generativa.webp',
      alt: 'guia-executivo-ia-generativa',
      descricao: 'Guia executivo sobre IA generativa',
      link: 'https://sucesso.qinetwork.com.br/guia-executivo-ia-generativa'
    },
    {
      img: './assets/images/conteudo/img-cloud-functions-como-automatizar-tarefas-com-a-solucao-faas-do-google.png',
      alt: 'cloud-functions-como-automatizar-tarefas-com-a-solucao-faas-do-google',
      descricao: 'Cloud Functions: como automatizar tarefas com a solução Faas do Google?',
      link: 'https://sucesso.qinetwork.com.br/cloud-functions'
    },
    {
      img: './assets/images/conteudo/img-o-valor-gerado-pelo-google-workspace.png',
      alt: 'o-valor-gerado-pelo-google-workspace',
      descricao: 'O valor gerado pelo Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/o-valor-gerado-pelo-google-workspace'
    },
    {
      img: './assets/images/conteudo/img-modernizacao-de-aplicacoes-como-as-solucoes-do-google-cloud-platform-podem-ajudar.png',
      alt: 'modernizacao-de-aplicacoes-como-as-solucoes-do-google-cloud-platform-podem-ajudar',
      descricao: 'Modernização de aplicações: como as soluções do Google Cloud Platform podem ajudar',
      link: 'https://sucesso.qinetwork.com.br/modernizacao-de-aplicacoes-solucoes-do-google-cloud-platform'
    },
    {
      img: './assets/images/conteudo/img-arquitetura-nativa-da-nuvem-diretrizes-para-migracao.png',
      alt: 'arquitetura-nativa-da-nuvem-diretrizes-para-migracao',
      descricao: 'Arquitetura nativa da nuvem: diretrizes para migração',
      link: 'https://sucesso.qinetwork.com.br/arquitetura-nativa-da-nuvem'
    },
    {
      img: './assets/images/conteudo/img-como-ser-mais-eficiente-com-o-google-cloud-platform.png',
      alt: 'como-ser-mais-eficiente-com-o-google-cloud-platform',
      descricao: 'DevOps: como ser mais eficiente com o Google Cloud Platform',
      link: 'https://sucesso.qinetwork.com.br/devops-eficiente-com-o-google-cloud-platform'
    },
    {
      img: './assets/images/conteudo/img-como-superar-os-desafios-da-migracao-para-a-nuvem.png',
      alt: 'como-superar-os-desafios-da-migracao-para-a-nuvem',
      descricao: 'Como superar os desafios da migração para a nuvem',
      link: 'https://sucesso.qinetwork.com.br/como-superar-os-desafios-da-migracao'
    },
    {
      img: './assets/images/conteudo/img-principios-de-otimizacao-de-custos.png',
      alt: 'nocoes-basicas-sobre-os-principios-de-otimizacao-de-custos',
      descricao: 'Noções básicas sobre os princípios de otimização de custos',
      link: 'https://sucesso.qinetwork.com.br/principios-de-otimizacao-de-custos'
    },
    {
      img: './assets/images/conteudo/img-eficiencia-operacional-da-ti-com-google-cloud.png',
      alt: 'eficiencia-operacional-da-ti-com-google-cloud',
      descricao: 'Eficiência Operacional da TI com Google Cloud',
      link: 'https://sucesso.qinetwork.com.br/eficiencia-operacional-ti'
    },
    {
      img: './assets/images/conteudo/img-transformacao-do-varejo-com-o-google-cloud.png',
      alt: 'transformacao-do-varejo-com-o-google-cloud',
      descricao: 'Transformação do varejo com o Google Cloud',
      link: 'https://sucesso.qinetwork.com.br/transformacao-do-varejo'
    },
    {
      img: './assets/images/conteudo/img-sap-google-cloud.png',
      alt: 'sap-google-cloud',
      descricao: 'SAP no Google Cloud',
      link: 'https://sucesso.qinetwork.com.br/sap-no-google-cloud'
    },
    {
      img: './assets/images/conteudo/img-como-implementar-programa-inovacao.png',
      alt: 'como-implementar-programa-inovacao',
      descricao: 'Como Implementar um Programa de Inovação na Nuvem na sua Empresa',
      link: 'https://sucesso.qinetwork.com.br/programa-de-inovacao'
    },
    {
      img: './assets/images/conteudo/img-quiz-lgpd-sua-empresa-esta-pronta.png',
      alt: 'quiz-lgpd-sua-empresa-esta-pronta',
      descricao: 'Quiz LGPD: a sua empresa está pronta?',
      link: 'https://sucesso.qinetwork.com.br/quiz-lgpd'
    },
    {
      img: './assets/images/conteudo/img-sua-jornada-ate-nuvem.png',
      alt: 'sua-jornada-ate-nuvem',
      descricao: 'Sua Jornada até a nuvem',
      link: 'https://sucesso.qinetwork.com.br/jornada-nuvem'
    },
    {
      img: './assets/images/conteudo/img-futuro-computacao-nuvem.png',
      alt: 'futuro-computacao-nuvem',
      descricao: 'O Futuro da Computação em Nuvem',
      link: 'https://sucesso.qinetwork.com.br/futuro-computacao-nuvem'
    },
    {
      img: './assets/images/conteudo/img-mudanca-organizacional.png',
      alt: 'mudanca-organizacional',
      descricao: 'Mudança organizacional: como preparar sua equipe',
      link: 'https://sucesso.qinetwork.com.br/mudanca-organizacional-como-preparar-sua-equipe'
    },
    {
      img: './assets/images/conteudo/img-lgpd-instituicoes-saude.png',
      alt: 'lgpd-instituicoes-saude',
      descricao: 'LGPD em instituições de saúde: como adequar processos com o Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/ebook-lgpd-em-instituicoes-de-saude'
    },
    {
      img: './assets/images/conteudo/img-importancia-sistema-nuvem.png',
      alt: 'importancia-sistema-nuvem',
      descricao: 'A importância dos sistemas em nuvem para grandes empresas de varejo',
      link: 'https://sucesso.qinetwork.com.br/sistemas-em-nuvem-para-empresas-de-varejo'
    },
    {
      img: './assets/images/conteudo/img-transformacao-digital-saude.png',
      alt: 'transformacao-digital-saude',
      descricao: 'Desafios da transformação digital na saúde',
      link: 'https://sucesso.qinetwork.com.br/desafios-da-transformacao-digital-na-saude'
    },
    {
      img: './assets/images/conteudo/img-lei-protecao-dados.png',
      alt: 'lei-protecao-dados',
      descricao: 'LGPD (Lei Geral de Proteção de Dados) - Compliance e Segurança da TI',
      link: 'https://sucesso.qinetwork.com.br/lgpd-compliance-e-seguranca-da-ti'
    },
    {
      img: './assets/images/conteudo/img-seguranca-escala.png',
      alt: 'seguranca-escala',
      descricao: 'Segurança em Escala com Cloud Computing: um minuto na vida do Google',
      link: 'https://sucesso.qinetwork.com.br/seguranca-em-escala-com-cloud-computing'
    },
    {
      img: './assets/images/conteudo/img-logistica-futuro.png',
      alt: 'logistica-futuro',
      descricao: 'Logística do futuro: os impactos das novas tecnologias para as empresas do setor',
      link: 'https://sucesso.qinetwork.com.br/logistica-do-futuro'
    },
    {
      img: './assets/images/conteudo/img-como-ampliar-produtividade.png',
      alt: 'como-ampliar-produtividade',
      descricao: 'Como ampliar produtividade de grandes empresas com o Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/como-ampliar-produtividade-de-grandes-empresas'
    },
    {
      img: './assets/images/conteudo/img-como-ferramentas-g-suite-podem-fortalecer-cultura.png',
      alt: 'como-ferramentas-g-suite-podem-fortalecer-cultura',
      descricao: 'eBook: Como ferramentas do Google Workspace podem fortalecer a cultura e o clima organizacional da sua empresa',
      link: 'https://sucesso.qinetwork.com.br/como-ferramentas-do-gsuite-podem-fortalecer-a-cultura-e-clima-organizacional-da-sua-empresa'
    },
    {
      img: './assets/images/conteudo/img-customização-da-nuvem.png',
      alt: 'customização-da-nuvem',
      descricao: 'Customização da nuvem - Integre suluções e aperfeiçoe sistemas digitais com o Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/customizacao-da-nuvem-com-o-g-suite'
    },
    {
      img: './assets/images/conteudo/img-geracao-millennial.png',
      alt: 'geracao-millennial',
      descricao: 'eBook: Geração Millennial e as ferramentas digitais corporativas',
      link: 'https://sucesso.qinetwork.com.br/geracao-millennial-e-as-ferramentas-digitais-corporativas'
    },
    {
      img: './assets/images/conteudo/img-processo-inovacao.png',
      alt: 'processo-inovacao',
      descricao: `eBook: Processo de inovação e mentoria da Qi Network auxiliam CIO's a inovar com segurança`,
      link: 'https://sucesso.qinetwork.com.br/processos-de-inovacao-e-mentoria-da-qi-network-auxiliam-cios-a-inovar-com-seguranca'
    },
    {
      img: './assets/images/conteudo/img-relatorio-pesquisa.png',
      alt: 'relatorio-pesquisa',
      descricao: 'Relatório de pesquisa: Por trás da confiança crescente na segurança da nuvem',
      link: 'https://sucesso.qinetwork.com.br/relatorio-de-pesquisa-por-tras-da-confianca-crescente-na-seguranca-da-nuvem'
    },
    {
      img: './assets/images/conteudo/img-investimento-transformacao-digital.png',
      alt: 'investimento-transformacao-digital',
      descricao: 'eBook: Como o investimento em transformação digital pode aumentar a rentabilidade da sua empresa',
      link: 'https://sucesso.qinetwork.com.br/como-o-investimento-em-transformacao-digital-pode-aumentar-a-rentabilidade-da-sua-empresa'
    },
    {
      img: './assets/images/conteudo/img-empresa-setor-financeiro.png',
      alt: 'empresa-setor-financeiro',
      descricao: 'eBook: Empresa do setor financeiro aumenta colaboração com o Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/empresa-do-setor-financeiro-aumenta-colaboracao-com-o-gsuite'
    },
    {
      img: './assets/images/conteudo/img-como-ceos-podem-transformar-uma-organizacao.png',
      alt: 'como-ceos-podem-transformar-uma-organizacao',
      descricao: `eBook: Como CEO's podem transformar uma organização tradicional em um negócio digital?`,
      link: 'https://sucesso.qinetwork.com.br/como-ceos-podem-transformar-uma-organizacao-tradicional-em-um-negocio-digital'
    },
    {
      img: './assets/images/conteudo/img-reducao-custos.png',
      alt: 'reducao-custos',
      descricao: 'eBook: Redução de custos e aumento da produtividade na sua empresa com o Google Workspace',
      link: 'https://sucesso.qinetwork.com.br/reducao-de-custos-e-aumento-da-produtividade-na-sua-empresa-com-o-gsuite'
    },
    {
      img: './assets/images/conteudo/img-migrar-cargas-trabalho.png',
      alt: 'migrar-cargas-trabalho',
      descricao: 'Migrar cargas de trabalho para a nuvem pública: um guia e um checklist de verificação essenciais',
      link: 'https://sucesso.qinetwork.com.br/migrar-cargas-de-trabalho-para-a-nuvem'
    },
    {
      img: './assets/images/conteudo/img-tres-dicas-para-tirar-maximo-proveito-plataformas-colaboracao.png',
      alt: 'tres-dicas-para-tirar-maximo-proveito-plataformas-colaboracao',
      descricao: 'eBook: três dicas para você tirar o máximo de plataforma de colaboração',
      link: 'https://sucesso.qinetwork.com.br/tres-dicas-para-voce-tirar-o-maximo-de-plataformas-de-colaboracao'
    },
    {
      img: './assets/images/conteudo/img-como-cios-estao-economizando.png',
      alt: 'como-cios-estao-economizando',
      descricao: `eBook: Como CIO's estão economizando em média 304% migrando para o Google Workspace`,
      link: 'https://sucesso.qinetwork.com.br/como-cios-estao-economizando-em-media-304-migrando-para-o-gsuite'
    }
  ]

  ngOnInit() {
    this.gerarId();
    this.ebooks = this.ebooksList;
    this.newTotal = this.ebooks.length;
    this.tamanho = this.ebooks.length;
  }

  gerarId(){
    for(let [i, book] of this.ebooksList.entries()){
      book.id = ++i;
   }
  }

  public buscar(b:string) {
    b = b ? b.trim():'';
    this.ebooks = this.ebooksList.filter(ba => ba.descricao.toLowerCase().includes(b.toLowerCase()));
    this.newTotal = this.ebooks.length;
    console.log(this.newTotal);

    if (this.buscaTotal !== this.newTotal) {
      this.buscaTotal = this.newTotal;
    } else if (!b) {
      this.buscaTotal = -1;
    }
  }
}
