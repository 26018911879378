import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: "app-slides",
  templateUrl: "./slides.component.html",
  styleUrls: ["./slides.component.scss"],
})
export class SlidesComponent implements OnInit {
  slides = [
    {
      src: "../../../../../assets/images/slides/frase1-1.jpeg",
      text: "Potencialize o futuro digital da sua empresa com serviços de excelência em Cloud!",
    },
    {
      src: "../../../../../assets/images/slides/novo-banner-1.jpg",
      mobileSrc: "../../../../../assets/images/slides/novo-banner-1-mobile.jpg",
      hideDarken: true,
      link: "https://sucesso.qinetwork.com.br/ebook-diagnostico-maturidade-wblio",
      text: "",
    },
    {
      src: "../../../../../assets/images/slides/frase2-1.jpeg",
      text: "Somos especialistas em Inovação na Nuvem com mais de 1.000 projetos!",
    },
    {
      src: "../../../../../assets/images/slides/frase3-2.jpeg",
      text: "Contribuímos para transformar desafios de negócios em soluções inovadoras.",
    },
    {
      src: "../../../../../assets/images/slides/frase4-3.jpeg",
      text: "Acelere a inovação, reduza custos e ganhe escalabilidade com nossa parceria.",
    },
    {
      src: "../../../../../assets/images/slides/frase5-1.jpeg",
      text: "Use o poder da IA Generativa para impulsionar a transformação digital.",
    },
    {
      src: "../../../../../assets/images/slides/frase6-3.jpeg",
      text: "Unindo forças para transformar seu negócio: Sua visão, nossa expertise.",
    },
  ];

  certifications = [
    "../../../../../assets/images/certifications/DevOps_Specialization_Outline.png",
    "../../../../../assets/images/certifications/GC-specialization-Infrastructure-outline.png",
    "../../../../../assets/images/certifications/GC-specialization-Work_Transformation-outline (1).png",
    "../../../../../assets/images/certifications/Google_Cloud_Partner_outline.png",
  ];
  alturaEmPixels: number;
  isDragging: boolean;
  isMobile: boolean;

  constructor() {}

  ngOnInit() {
    this.atualizarAlturaEmPixels();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.atualizarAlturaEmPixels();
  }

  atualizarAlturaEmPixels() {
    const alturaDaJanela = window.innerHeight;
    this.isMobile = window.innerWidth < 1150;

    const vh = window.innerWidth < 750 ? 0.65 : 1; /* 0.5 é igual 50vh */
    this.alturaEmPixels = Math.round(alturaDaJanela * vh);
  }

  onMouseDown(): void {
    this.isDragging = false;
  }

  onMouseMove(): void {
    this.isDragging = true;
  }

  onMouseUp(link?: string): void {
    if (!this.isDragging && link) {
      window.open(link, "_blank"); // Abre o link apenas se não houve arrasto
    }
  }
}
