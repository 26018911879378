const data = {
  "implementacao-de-solucao": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Implementação de Solução",
    titulo: "Implementação de Solução: Planejamento e Execução Eficaz",
    subTitulo:
      "Planejamento e Execução Eficaz para um Ambiente de Produção Bem-Sucedido",
    cards: {
      titulo:
        "A importância da ativação, migração e configuração em um ambiente de produção bem-sucedido",
      conteudo: [
        "<p>O serviço de <strong>implementação de solução</strong> é entregue através de sprints, que contêm suas fases e a execução das atividades de <strong>ativação, migração e configuração</strong> de um sistema já desenvolvido, testado e homologado em um ambiente de produção do cliente.</p> <p>As atividades de ativação do sistema e as configurações específicas serão devidamente planejadas entre nossa equipe e a equipe do cliente através da fase de Design de Solução.</p>",
        "<p>Os objetivos incluem <strong>preparar um ambiente de produção, executar as configurações pré-definidas, homologar todas as configurações</strong> executadas e comunicar o sucesso da implementação para os usuários.</p>",
        "<p>Os benefícios englobam a <strong>gestão do projeto de implementação, a participação de especialistas na preparação, execução e homologação das configurações</strong>, e a garantia de qualidade dos entregáveis de implementação.</p>",
      ],
    },
  },
  "migracao-dados-google-workspace": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Migração de Dados para Google Workspace",
    titulo: "Migração de Dados e Documentos para o Google Workspace",
    subTitulo:
      "Planejamento, Execução e Garantia de Qualidade na Transição para um Ambiente Digital Integrado",
    cards: {
      titulo: "Planeje sua migração para o Google Workspace com excelência!",
      conteudo: [
        "<p>A migração de dados e documentos para o <strong>Google Workspace</strong> é um passo crucial para qualquer organização que busca melhorar a eficiência e a colaboração em um ambiente digital.</p><p>Nosso serviço de migração é cuidadosamente estruturado para garantir uma <strong>transição suave e sem interrupções</strong>, permitindo que sua equipe aproveite ao máximo as ferramentas do Google Workspace.</p>",
        "<p>Trabalhamos em estreita colaboração com sua equipe durante a fase de Design de Solução, onde planejamos detalhadamente todas as atividades de ativação e configurações específicas necessárias para o seu ambiente de produção.</p>",
        "<p>Nossa missão é facilitar sua transição para o <strong>Google Workspace</strong>, proporcionando um ambiente digital que promove a colaboração, aumenta a produtividade e melhora a comunicação dentro da sua organização.</p>",
      ],
    },
  },
  "campanha-engajamento-google-workspace": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Campanha de Engajamento",
    titulo: "Campanha de Engajamento em Mudanças Tecnológicas",
    subTitulo: "Estruturando o Compromisso dos Colaboradores",
    cards: {
      titulo:
        "Mantenha seus colaboradores engajados em todas as fases do projeto!",
      conteudo: [
        "<p>Implementar uma <strong>nova solução tecnológica</strong> em uma empresa exige ações bem estruturadas para engajar os colaboradores e garantir seu compromisso com o propósito da mudança. O grande desafio é manter esse engajamento ao longo de todas as fases do processo, planejamento, implementação, treinamento e suporte. É comum observar um entusiasmo inicial que diminui com o tempo.</p>",
        "<p>A <strong>Campanha de Engajamento</strong>, semelhante a uma campanha publicitária interna, é uma solução para esse problema. Ela inclui a criação de logotipo, slogan, identidade visual, gamificação, brindes, comunicados personalizados e decoração da empresa no dia do Global Go Live.</p>",
        "<p>O principal objetivo da Campanha de Engajamento da Qi Network é manter líderes e colaboradores comprometidos com o projeto. Usando técnicas visuais e lúdicas, como a criação de logotipos, slogans e identidades visuais atrativas, além de estratégias de gamificação com reconhecimentos e premiações exclusivas, a campanha motiva todos a se engajarem com a implementação da nova tecnologia. O <strong>benefício principal</strong> é que os colaboradores aceitam a mudança de tecnologia de maneira positiva e se comprometem ativamente com o propósito da transformação.</p>",
      ],
    },
  },
  "suporte-tecnico-google-workspace": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Suporte Técnico Google Workspace",
    titulo: "Suporte Técnico Especializado para Google Workspace",
    subTitulo:
      "Segurança, Eficiência e Conformidade para Maximizar o Potencial da Sua Plataforma de Colaboração",
    cards: {
      titulo:
        "Garanta o suporte técnico especializado que sua empresa precisa!",
      conteudo: [
        "<p>O serviço de <strong>Suporte Técnico</strong> é composto por uma equipe altamente qualificada de especialistas em Google Workspace, cuja missão é fornecer apoio técnico abrangente à equipe de TI dos clientes, abrangendo desde a resolução de problemas técnicos, passando pelo esclarecimento de dúvidas, até orientações sobre a usabilidade da plataforma Google Workspace.</p>",
        "<p>O principal objetivo do serviço é garantir que os clientes tenham acesso a um conhecimento técnico especializado no uso da plataforma, resultando em uma utilização mais eficiente e segura da ferramenta, maximizando seus recursos e funcionalidades.</p>",
        "<p>A adoção deste serviço traz benefícios significativos para os clientes, como a <strong>redução substancial nos riscos relacionados à segurança da informação</strong>, com especialistas sempre à disposição, permitindo que os clientes operem com maior tranquilidade e confiança.</p>",
      ],
    },
  },
  "servicos-especializados-google-workspace": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Serviços Especializados Google Workspace",
    titulo: "Suporte Especializado Google Workspace",
    subTitulo:
      "Esteja sempre por dentro das melhores práticas do uso do Google Workspace",
    cards: {
      titulo: "Tenha especialistas ao seu lado para qualquer necessidade!",
      conteudo: [
        "<p>O serviço de especialista inclui tudo que tem no serviço de suporte além de disponibilizar  equipe de especialistas  onde realiza todas as configurações referentes ao painel Google Workspace para os clientes de acordo com as horas mensais contratadas.</p>",
        "<p>Quando um cliente abre um chamado no suporte, os especialistas executam as tarefas necessárias para ele. Nosso objetivo é ser o braço direito do cliente em relação às configurações operacionais na plataforma do Google Workspace.</p>",
        "<p>O Especialista Google Workspace cuida das configurações gerais do painel, incluindo configuração sobre o uso do Workspace, configuração de usuários e grupos, configuração técnica do painel de controle administrativo e políticas de segurança, treinamentos técnicos, treinamentos de colaboração e comunicação.</p>",
      ],
    },
  },
  "google-appsheet": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Google AppSheet",
    titulo: "A Revolução do Desenvolvimento de Aplicativos: AppSheet",
    subTitulo:
      "Acelerando a Produtividade com Integrações Profundas e Sem Código",
    cards: {
      titulo:
        "Desenvolva aplicativos poderosos e automatize processos sem escrever uma linha de código!",
      conteudo: [
        "<p>O <strong>AppSheet</strong> capacita qualquer pessoa a criar aplicativos poderosos e automações em uma plataforma unificada e sem código. Com ele, é possível criar soluções rapidamente, gerenciar dados com mais eficiência, automatizar fluxos de trabalho para economizar tempo e compartilhar insights com as partes interessadas</p>",
        "<p>A produtividade é acelerada graças ao desenvolvimento sem código, aproveitando as <strong>integrações profundas entre o AppSheet e o Google Workspace</strong>. A plataforma permite acessar pastas compartilhadas no Drive para dados e conteúdo de aplicativos, além de criar, ler, atualizar e excluir dados do Planilhas diretamente do aplicativo.</p>",
        "<p> Ela também integra e atualiza eventos de calendários existentes, expande a funcionalidade de formulários com recursos de entrada de dados e se conecta a outros serviços como Gmail, Documentos e Meet.</p>",
      ],
    },
  },
  "google-voice": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Google Voice",
    titulo: "Google Voice: Telefonia na Nuvem com Inteligência Artificial",
    subTitulo:
      "A Solução Inovadora para Comunicações Empresariais Eficientes e Seguras",
    cards: {
      titulo: "Transforme sua comunicação empresarial com Google Voice!",
      conteudo: [
        "<p>O <strong>Google Voice</strong> é uma solução de telefonia nativa na nuvem, fácil de administrar, que utiliza a inteligência artificial do Google para proporcionar uma experiência intuitiva, ajudando as pessoas a se conectarem e ganharem tempo. Um dos principais diferenciais do Google Voice é o seu correio de voz com transcrição, que usa modelos de aprendizado de máquina para transcrever mensagens em oito idiomas diferentes.</p>",
        "<p>A detecção de spam, também impulsionada pela inteligência artificial do Google, bloqueia chamadas indesejadas, permitindo que os usuários se concentrem em assuntos importantes. O sistema de autoatendimento das chamadas é altamente personalizável, com horário comercial integrado, conversão de texto em fala em diversos idiomas e menus de múltiplos níveis, facilitando a navegação dos chamadores. Além disso, os administradores podem configurar grupos de toque para rotear chamadas de maneira inteligente, permitindo que várias pessoas na organização façam e recebam chamadas do mesmo número do Google Voice.</p>",
        "<p> A gravação de chamadas é simples e segura, podendo ser configurada para ocorrer automaticamente ou ser controlada manualmente pelos usuários, com as gravações acessíveis no Google Vault para aplicação de políticas de retenção e conformidade. Por fim, a integração do Vault proporciona um sistema de eDiscovery incorporado, reunindo os registros de telefonia em um único sistema legal de descoberta e retenção, que inclui os principais aplicativos do Google Workspace.</p>",
      ],
    },
  },
  "chrome-enterprise-upgrade": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Chrome Enterprise Upgrade",
    titulo: "Vantagens do Chrome Enterprise Upgrade",
    subTitulo: "Segurança e Eficiência para Empresas",
    cards: {
      titulo:
        "Aumente a segurança e eficiência da sua empresa com Chrome Enterprise!",
      conteudo: [
        "<p>O navegador Chrome para empresas, conhecido como Chrome Enterprise, oferece os mesmos recursos do navegador comum, mas com diferenciais na sua implantação e gestão. Com o <strong>Chrome Enterprise</strong>, a Google disponibiliza um conjunto de aplicativos e recursos que aumentam a eficiência, produtividade e agilidade no ambiente corporativo.</p>",
        "<p>A segurança é um ponto forte, com atualizações rápidas e políticas intuitivas que protegem os usuários e dados. Empresas que utilizam dispositivos baseados na nuvem beneficiam-se de uma segurança integrada que garante tranquilidade em cada endpoint.</p>",
        "<p> Além disso, o controle detalhado na nuvem permite uma gestão centralizada de dispositivos e plataformas. Optar pelo Chrome Enterprise representa um investimento inteligente, reduzindo custos, riscos de segurança, inatividade e improdutividade.</p>",
      ],
    },
  },
  "chrome-os-flex": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Chrome OS Flex",
    titulo:
      "ChromeOS Flex: Transformando Dispositivos Antigos em Ferramentas de Alta Performance",
    subTitulo: "A Evolução do Sistema Operacional para Macs e PCs",
    cards: {
      titulo: "Dê nova vida a dispositivos antigos com ChromeOS Flex!",
      conteudo: [
        "<p>O <strong>ChromeOS Flex</strong> é um sistema operacional inovador que transforma Macs e PCs antigos em dispositivos rápidos, seguros e fáceis de gerenciar, priorizando o uso da nuvem. Desenvolvido para melhorar o desempenho e impulsionar o ensino e o aprendizado, o ChromeOS Flex destaca-se por suas características únicas. A segurança proativa do sistema protege contra ameaças crescentes como ransomware, malware e erros de funcionários, garantindo um ambiente digital seguro.",
        "<p>A implantação é rápida e fácil, podendo ser realizada via USB ou pela rede da empresa, e com o <strong>Upgrade do Chrome Enterprise</strong>, a gestão de aplicativos e políticas pode ser feita de qualquer lugar. A experiência de trabalho é otimizada com atualizações em segundo plano que mantêm os dispositivos rápidos e produtivos. Além disso, o ChromeOS Flex promove a sustentabilidade, revitalizando hardware existente para reduzir lixo eletrônico e consumo de energia. </p>",
        "<p>Entre seus benefícios estão a capacidade de trabalhar melhor na nuvem, download automático de perfis, gerenciamento remoto via Google Admin Console, inicialização rápida, desempenho ágil, e armazenamento de dados em centrais neutras em carbono. O sistema também oferece atualizações automáticas, dicas personalizadas, suporte 24 horas e segurança reforçada, eliminando a necessidade de antivírus.</p>",
      ],
    },
  },
  "gemini-google-workspace": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Gemini para Google Workspace",
    titulo: "Gemini para Google Workspace",
    subTitulo: "Revolucionando a Colaboração e Produtividade",
    cards: {
      titulo:
        "Aumente a produtividade com a IA do Gemini para Google Workspace!",
      conteudo: [
        "<p>O <strong>Gemini para Google Workspace</strong> é uma ferramenta colaborativa avançada que pode atuar como instrutora, parceira e fonte de inspiração, além de ajudar no aumento da produtividade.</p>",
        "<p>Tudo isso garantindo que usuários e organizações tenham controle sobre os próprios dados. No ano passado, mais de um milhão de pessoas e milhares de empresas usaram a IA generativa no Workspace.</p>",
        "<p>Com o <strong>Gemini</strong> para Google Workspace, as organizações podem criar, conectar e colaborar de uma forma totalmente nova.</p>",
      ],
    },
  },
  "google-meet-hardware": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Google Meet Hardware",
    titulo: "Google Meet Hardware",
    subTitulo: "Licença e Integração para Videoconferências Eficientes",
    cards: {
      titulo: "Transforme suas videoconferências com o Google Meet Hardware!",
      conteudo: [
        "<p>O <strong>Google Meet Hardware</strong> é uma licença do Google Meet que opera em conjunto com dispositivos de hardware específicos e calendários de recursos do Google Workspace, proporcionando uma configuração e execução de videoconferências remotas de maneira rápida e fácil.</p>",
        "<p>Para garantir um funcionamento impecável, é essencial que o hardware do Google Meet seja totalmente compatível com as edições do Google Workspace através da integração com o Google Agenda. Em parceria com a Poly, oferecemos produtos de alta qualidade para essa finalidade.</p>",
      ],
    },
  },
  "qi-academy": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Qi Academy",
    titulo: "Qi Academy",
    subTitulo: "Capacite-se com Flexibilidade e Profundidade",
    cards: {
      titulo: "Aprimore suas habilidades com os cursos da Qi Academy!",
      conteudo: [
        "<p>Nossa equipe de especialistas em Google Cloud está empenhada na idealização e atualização contínua dos nossos cursos.</p>",
        "<p>Desde os fundamentos essenciais do Google Workspace até os cursos avançados sobre o Painel de Controle, nosso objetivo é proporcionar uma experiência de aprendizado robusta e flexível.</p>",
        "<p>Você e sua equipe podem acessar as aulas a qualquer momento, utilizando nosso ambiente de ensino à distância, garantindo assim aprendizado contínuo e adaptável às suas necessidades específicas.</p>",
      ],
    },
  },
  "gestor-assinaturas-email": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Gestor de Assinaturas de Email",
    titulo: "Gestor de Assinaturas de Email",
    subTitulo: "Automatização no controle de assinaturas",
    cards: {
      titulo: "Padronize e gerencie as assinaturas de email da sua empresa!",
      conteudo: [
        "<p>O <strong>Gestor de Assinaturas</strong> é uma ferramenta essencial integrada ao painel de administrador do Google Workspace. Ele permite a padronização das assinaturas de email em todo o domínio da sua empresa. Com o Gestor, você garante a uniformidade e a precisão das informações nas assinaturas, mantendo o padrão visual da identidade corporativa.</p>",
        "<p>Personalize as assinaturas de acordo com suas necessidades específicas, incluindo fotos, links para redes sociais, informações de contato e banners para promover eventos ou lançamentos de novos produtos.</p>",
        "<p>Além disso, tenha controle completo sobre múltiplos domínios, simplificando ainda mais o gerenciamento de assinaturas dentro da organização. </br><a href='https://gower.qinetwork.com.br/' target='_blank'><strong>Clique aqui</strong></a> e saiba mais!</p>",
      ],
    },
  },
  "adocao-google-workspace": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Adoção Google Workspace",
    titulo: "Adoção Google Workspace",
    subTitulo: "Aumente o Engajamento e a Adoção das Soluções",
    cards: {
      titulo: "Garanta o sucesso da adoção do Google Workspace na sua empresa!",
      conteudo: [
        "<p>Identificar um desafio, analisar, planejar, desenvolver e implementar uma solução muitas vezes não é suficiente para alcançar os objetivos esperados. A <strong>não adoção ou adoção inadequada</strong> de uma solução pode impedir que ela traga o valor esperado, impactando os resultados pretendidos e o retorno sobre o investimento (ROI).</p>",
        "<p>Por isso, é essencial que as empresas possuam um <strong>Projeto de Adoção de Solução</strong> para garantir o engajamento e comprometimento dos colaboradores, extraindo o máximo valor das soluções implementadas e potencializando os negócios. </p>",
        "<p>O serviço de Adoção da Solução foi criado para conduzir a empresa, seus líderes e colaboradores na adoção eficaz de soluções, gerenciando e executando todo o processo, preparando, comunicando, formando e monitorando os envolvidos, além de engajá-los por meio de campanhas com gamificação e premiações.</p>",
      ],
    },
  },

  "implementacao-melhores-praticas-politicas-seguranca-workspace": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Melhores Práticas e Segurança no Workspace",
    titulo: "Implementação de melhores práticas e politicas de Segurança no Workspace",
    subTitulo: "Aprimore a segurança do Google Workspace da sua empresa com avaliações de configuração, treinamentos, e implementação de soluções de mobilidade.",
    cards: {
      titulo: "Assegure que a sua empresa tenha uma integração bem-sucedida com o Google Workspace!",
      conteudo: [
        "<p>Esse serviço vai auxiliar a sua empresa a aproveitar todos os recursos de segurança que o <strong>Google Workspace</strong> dispõe, avaliando os processos atuais de configuração de domínio e políticas de segurança de dados da sua empresa.</p>",
        "<p>Além disso, vamos manter a sua equipe atualizada sobre as novas ferramentas de <strong>segurança</strong>, as melhores práticas e realizar treinamentos para administradores e usuários finais. O serviço ainda inclui o desenvolvimento de uma estratégia mobile e a implementação do <strong>Workspace MDM</strong> (do inglês Mobile Device Management / Gerenciamento de Dispositivos Mobile).</p>",
        "<p>Ter uma estratégia de mobilidade bem definida e uma solução de gerenciamento viável são os pontos chaves para empresas que buscam versatilidade para seus colaboradores.</p>",
      ],
    },
  },

  "implementacao-meu-drive-drive-compartilhado": {
    img: "./assets/images/solucoes/img-solucoes-top3.png",
    imgMobile: "./assets/images/solucoes/img-solucoes-top-mobile.png",
    produto: "Meu Drive e Drive Compartilhado",
    titulo: "Implementação do Meu drive/ Drive compartilhado",
    subTitulo: "Otimize o uso do Google Drive com configurações estratégicas e suporte para colaboração segura e eficiente.",
    cards: {
      titulo: "Garanta o sucesso da adoção do Google Workspace na sua empresa!",
      conteudo: [
        "<p>O Serviço de Implantação do Meu Drive e Drive Compartilhado do Google Workspace é uma solução para empresas que desejam <strong>implementar</strong> e otimizar o uso do Meu Drive e do Drive Compartilhado em sua infraestrutura de trabalho colaborativo.</p>",
        "<p>O serviço possui uma abordagem estruturada e orientada para estruturação do drive, visando melhorar a colaboração, a produtividade e a segurança dos dados, oferecendo uma configuração personalizada e uma implantação segura.</p>",
        "<p><strong>Entregáveis do serviço:</strong> <br /> <ul> <li><strong>Configuração personalizada:</strong> <br />Configuração do Meu Drive e/ou Drive Compartilhado de acordo com as preferências, requisitos de segurança e fluxos de trabalho. </li> <li><strong>Estruturação de pastas e permissões:</strong> <br /> De acordo com a estrutura da sua empresa, implementamos uma estrutura de pastas e a atribuição de permissões adequadas a cada grupo de usuários. <ul> <li>Regras de acesso;</li> <li>Permissionamento (Adm conteúdo, administradores, leitor, comentar)</li> <li>Gerenciamento de participantes</li></ul></li> </ul></p>",
      ],
    },
  },
};

export default data;
